<template>
  <v-data-iterator :loading="!contratsLoaded" loading-text="Chargement des contrats en cours..." :items="contracts">
    <template v-slot:default="{ items }">
      <v-card @click="setContract(contract)" outlined elevation="0" v-for="contract in items" :key="contract.id">
        <div class="pa-3">
          <v-row no-gutters class="pl-3 mb-2">
            <v-col class="pa-0 ma-0">
              <h3 class="primary--text">{{ contract.name }}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pa-0 ma-0 d-flex">
              <h3 class="grey--text">{{ getContractType(contract) }}</h3>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="contract.assets.length === 0">
            <v-col cols="12">
              <h3 class="font-italic grey--text">Pas d'équipement associé</h3>
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col cols="6">
              <h3 class="grey--text">{{ contract.assets.length }} équipement<span
                  v-if="contract.assets.length > 1">s</span> associé<span v-if="contract.assets.length > 1">s</span>
              </h3>
            </v-col>
            <v-col cols="6">
              <span class="primary--text" v-if="contract.prestataire !== null">{{
                  contract.prestataire.raison_sociale
                }}</span>
              <h3 class="primary--text" v-else>Pas de prestataire associé</h3>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="12" class="pa-0 ma-0">
              <h4 class="grey--text">{{ contract.reference }}</h4>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2" v-if="!contract.archived">
            <v-col cols="12" class="pa-0 ma-0">
              <h5 v-if="isOutdated(contract) && contract.type === 'controle'" class="red--text"><v-icon color="red">mdi-alert</v-icon>La date de ce contrôle obligatoire est dépassé.</h5>
              <h5 v-else-if="isControleSoon(contract)" class="orange--text"><v-icon color="orange">mdi-alert</v-icon> Ce contrôle obligatoire arrive bientôt à échéance.</h5>
              <h5 v-else-if="isAssuranceOutdated(contract)" class="red--text"><v-icon color="red">mdi-alert</v-icon> Cette assurance est caduque.</h5>
              <h5 v-else-if="isAssuranceSoon(contract)" class="orange--text"><v-icon color="orange">mdi-alert</v-icon> Cette assurance arrive bientôt à échéance.</h5>
              <h5 v-else-if="isOutdated(contract)" class="red--text"><v-icon color="red">mdi-alert</v-icon>Ce contrat est caduque.</h5>
              <h5 v-else-if="isSoon(contract)" class="orange--text"><v-icon color="orange">mdi-alert</v-icon> Ce contrat arrive bientôt à échéance.</h5>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <!--            <v-col cols="12" md="3">
                          <v-chip color="primary" v-if="asset.category !== null">
                            <span class="white&#45;&#45;text">{{ asset.category.name }}</span>
                          </v-chip>
                        </v-col>
                        <v-col cols="12" md="9">
                          <span class="font-italic" v-if="last_intervention(asset) !== null">Dernière intervention le {{
                              last_intervention_date(asset)
                            }}</span>
                          <span class="font-italic" v-else>Aucune intervention réalisée</span><br>
                          <span class="font-italic">Intervention non notée</span>
                        </v-col>-->
          </v-row>
        </div>
      </v-card>
    </template>
  </v-data-iterator>
</template>

<script>
import {ContractMixin} from "@/mixins/contracts/ContractMixin";
import dayjs from "dayjs";

export default {
  name: "ContractList",
  mixins: [ContractMixin],
  props: {
    contratsLoaded: Boolean,
  },
  computed: {
    contracts() {
      return this.$store.getters["contracts/getFilteredContracts"];
    }
  },
  methods: {
    setContract(contract) {
      const path = `/contracts/${contract.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    },
    isControleSoon(contract) {
      let limit_date = dayjs(contract.date_end).add(-30, 'day');
      return dayjs().isAfter(limit_date) && contract.type === "controle";
    },
    isAssuranceSoon(contract) {
      let limit_date = dayjs(contract.date_end).add(-30, 'day');
      return dayjs().isAfter(limit_date) && contract.type === "assurance";
    },
    isAssuranceOutdated(contract) {
      let limit_date = dayjs(contract.date_end).add(0, 'day');
      return dayjs().isAfter(limit_date) && contract.type === "assurance";
    },
    isSoon(contract) {
      let limit_date = dayjs(contract.date_end).add(-30, 'day');
      return dayjs().isAfter(limit_date);
    },
    isOutdated(contract) {
      let limit_date = dayjs(contract.date_end).add(0, 'day');
      return dayjs().isAfter(limit_date);
    },
  },

}
</script>

<style scoped>

</style>