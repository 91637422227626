<template>
  <v-slide-group>
    <slot></slot>
    <v-slide-item class="mt-6 mx-2">
      <v-text-field label="Nom" v-model="search_name"></v-text-field>
    </v-slide-item>
    <v-slide-item class="mt-6 mx-2">
      <v-select label="Type" item-text="label" item-value="value" :items="types" v-model="search_type"></v-select>
    </v-slide-item>

    <v-slide-item class="mt-7 mx-2">
      <v-checkbox v-model="filter_soon" label="Bientôt caduque"></v-checkbox>
    </v-slide-item>
    <v-slide-item>
          <div>
            <SiteTreeSelector v-model="site_tree"/>
          </div>
        </v-slide-item>
    <v-slide-item class="mt-6 mx-2">
      <v-btn @click="resetFilters" text color="red"><v-icon>mdi-trash-can-outline</v-icon>Retirer</v-btn>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: "ContractFilter",
  mixins: [],
  components: {SiteTreeSelector},
  async mounted() {
    if(this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
    this.search_type = this.$route.query.type || "";
    this.filter_soon = this.$route?.query?.soon === "true";
  },
  methods: {
    resetFilters() {
      this.search_type = null;
      this.search_name = null;
      this.site_tree = [];
      this.filter_soon = false;
    }
  },
  computed: {
    types() {
      return [
        {"value": "", "label": "Tous les contrats"},
        {"value": "simple", "label": "Maintenance simple"},
        {"value": "preventive", "label": "Maintenance préventive"},
        {"value": "controle", "label": "Contrôle obligatoire"},
        {"value": "assurance", "label": "Assurance"},
      ];
    },
    search_name: {
      get() {
        return this.$store.getters["contracts/getSearchName"];
      },
      set(value) {
        return this.$store.commit("contracts/setSearchName", value)
      }
    },
    search_type: {
      get() {
        return this.$store.getters["contracts/getSearchType"];
      },
      set(value) {
        return this.$store.commit("contracts/setSearchType", value)
      }
    },
    site_tree: {
      get() {
        return this.$store.getters["contracts/getSiteTree"];
      },
      set(value) {
        this.$store.commit("contracts/setSiteTree", value);
      }
    },
    filter_soon: {
      get() {
        return this.$store.getters["contracts/getSoon"];
      },
      set(value) {
        this.$store.commit("contracts/setSoon", value);
      }
    },
  }
}
</script>

<style scoped>

</style>