<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title v-if="operation === 'create'">Nouveau contrat</v-toolbar-title>
      <v-toolbar-title v-if="operation === 'update'">Mettre à jour le contrat</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form ref="newContractForm">
        <v-text-field label="Nom du contrat" :rules="[notNullNorEmpty]" v-model="contract.name"></v-text-field>
        <v-text-field label="Référence" :rules="[notNullNorEmpty]" v-model="contract.reference"></v-text-field>
        <v-divider></v-divider>
        <v-row justify="space-between">
          <span class="mt-7 mx-1"><v-icon>mdi-filter</v-icon>Filtrer par</span>
          <SiteTreeSelector v-model="assets_site_tree" />
        </v-row>
        <v-autocomplete class="my-n2" :rules="[]" label="Équipements" multiple :items="assets" item-value="id"
                        item-text="name" deletable-chips chips
                        v-model="contract.assets"></v-autocomplete>
        <v-divider></v-divider>
        <v-menu :close-on-content-click="false" max-width="100%">
          <template v-slot:activator="{on, attrs}">
            <v-text-field v-on="on" v-bind="attrs" :rules="[notNullNorEmpty]"
                          :label="contract.type !== 'controle' ? 'Date de début' : 'Date de dernier contrôle'"
                          :value="getFormattedDate(contract.date_start)"></v-text-field>
          </template>
          <v-date-picker v-model="contract.date_start"></v-date-picker>
        </v-menu>
        <v-menu :close-on-content-click="false" max-width="100%">
          <template v-slot:activator="{on, attrs}">
            <v-text-field v-on="on" v-bind="attrs" :rules="[notNullNorEmpty]"
                          :label="contract.type !== 'controle' ? 'Date de fin' : 'Date du prochain contrôle'"
                          :value="getFormattedDate(contract.date_end)"></v-text-field>
          </template>
          <v-date-picker v-model="contract.date_end"></v-date-picker>
        </v-menu>
        <v-select :rules="[notNullNorEmpty]" label="Type" v-model="contract.type" :items="type" item-value="value"
                  item-text="label"></v-select>
        <v-row no-gutters justify="end">
          <v-switch class="mr-4" v-model="showOldInput" label="Entrer le coût total"></v-switch>
        </v-row>
        <v-row v-if="!showOldInput">
          <v-col cols="6">
            <v-text-field v-model="periodes" :rules="[value => !isNaN(value)]" type="number" label="Nombre de périodes"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="cost_per_periode" :rules="[value => !isNaN(value)]" type="number" step="0.01" label="Coût par période"></v-text-field>
          </v-col>
          <v-col class="my-0" cols="12">
            <span class="body-1">Coût total du contrat : {{ calculated_cost }} €</span>
          </v-col>
        </v-row>
        <v-text-field v-else :rules="[notNullNorEmpty]" label="Coût" v-model="cost" type="number"></v-text-field>
        <v-autocomplete label="Prestataire" v-model="contract.prestataire" :items="prestataires"
                        item-text="raison_sociale" item-value="id"></v-autocomplete>
        <v-text-field v-if="contract.type !== 'controle'" label="Numéro de série"
                      v-model="contract.numero_serie"></v-text-field>
        <v-select label="Périodicité" v-model="contract.periodicite"
                  :items="periodicite" item-text="label"
                  item-value="value"></v-select>
        <v-checkbox label="Renouveler automatiquement le contrat" v-model="contract.automatic_renew"></v-checkbox>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" v-if="operation === 'create' || this.operation === 'reupdate'" text
             @click="createContract">
        Créer le contrat
      </v-btn>
      <v-btn color="primary" v-if="operation === 'update' || this.operation === 'reupdate'" text
             @click="updateContract">
        Mettre à jour
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {ContractMixin} from "@/mixins/contracts/ContractMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";

export default {
  name: "NewContractSidebar",
  components: {SiteTreeSelector},
  mixins: [DateUtilMixin, ContractMixin, ValidationRulesMixin],
  props: {
    operation: String,
    contractToUpdate: Object,
  },
  async mounted() {
    if (this.$store.getters["assets/allAssets"].length === 0) {
      await this.$store.dispatch("assets/fetchAssets");
    }
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
    if (this.$store.getters["contracts/allContracts"].length === 0) {
      await this.$store.dispatch("contracts/fetchContracts");
    }
    if (this.$store.getters["prestataires/allPrestataires"].length === 0) {
      await this.$store.dispatch("prestataires/fetchPrestataires");
    }
    this.$store.commit("assets/resetFilters");
  },
  data() {
    let emptyContract = {
      "name": "",
      "reference": "",
      "date_start": null,
      "date_end": null,
      "type": "",
      "cost": null,
      "assets": [],
      "numero_serie": "",
      "prestataire": null,
      "periodicite": null,
      "contract": null,
      "automatic_renew": false,
    }
    let contractToUpdate = {...this.contractToUpdate};
    if (this.operation === "update") {
      contractToUpdate.assets = this.contractToUpdate.assets.map(asset => typeof asset === "number" ? asset : asset.id)
    }
    return {
      contract: this.operation === "update" ? contractToUpdate : emptyContract,
      type: [
        {"value": "simple", "label": "Maintenance simple"},
        {"value": "preventive", "label": "Maintenance préventive"},
        {"value": "controle", "label": "Contrôle obligatoire"},
        {"value": "assurance", "label": "Assurance"},
      ],
      periodicite: [
        {"value": "annuelle", "label": "Annuelle"},
        {"value": "semestrielle", "label": "Semestrielle"},
        {"value": "trimestrielle", "label": "Trimestrielle"},
        {"value": "mensuelle", "label": "Mensuelle"},
        {"value": "bimensuelle", "label": "Bimensuelle"}
      ],
      showOldInput: this.operation === "update",
      cost: contractToUpdate.cost,
      periodes: null,
      cost_per_periode: null,
    }
  },
  computed: {
    assets() {
      return [...this.$store.getters["assets/getFilteredAssets"]];
    },
    prestataires() {
      return this.$store.getters["prestataires/allPrestataires"];
    },
    assets_site_tree: {
      get() {
        return this.$store.getters["assets/getFilterSiteTree"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterSiteTree", value)
      }
    },
    calculated_cost() {
      if(!isNaN(this.cost_per_periode) && !isNaN(this.periodes)) {
        let cost = this.cost_per_periode * this.periodes;
        return parseFloat(Math.round(cost * 100) / 100).toFixed(2);
      } else {
        return 0;
      }
    }
  },
  methods: {
    async createContract() {
      if(this.$refs.newContractForm.validate()) {
        this.contract.cost = this.showOldInput ? this.cost : this.periodes * this.cost_per_periode;
        this.cleanFields();
        await this.$store.dispatch("contracts/postContract", this.contract);
        this.$store.dispatch("drawer/closeDialog");
        this.resetFields();
        await this.$store.dispatch("contracts/fetchContracts");
        this.$store.dispatch("assets/fetchAssets");
      }
    },
    async updateContract() {
      if(this.$refs.newContractForm.validate()) {
        this.contract.cost = this.showOldInput ? this.cost : this.periodes * this.cost_per_periode;
        this.cleanFields();
        this.$store.dispatch("contracts/putContract", this.contract);
        this.$store.dispatch("drawer/closeDialog");
        this.resetFields();
        await this.$store.dispatch("contracts/fetchContracts");
        this.$store.dispatch("assets/fetchAssets");
      }
    },
    cleanFields() {
      this.contract.assets = this.contract.assets.map(asset => typeof asset === "number" ? asset : asset.id);
      this.contract.prestataire = this.contract.prestataire !== null && typeof this.contract.prestataire === "number" ? this.contract.prestataire : this.contract?.prestataire?.id;
    },
    resetFields() {
      this.contract = {
        "name": "",
        "reference": "",
        "date_start": null,
        "date_end": null,
        "type": "",
        "cost": null,
        "assets": null,
        "numero_serie": "",
        'prestataire': null,
        "periodicite": null
      }
    },
    uniqueAsset() {
      if(this.operation === "update") return true;
      let selected_assets = this.contract.assets.map(asset_id => this.assets.filter(asset => asset.id === asset_id)[0]);
      return selected_assets.filter(asset => asset.contract !== null).length === 0 || "Cet équipement est déjà associé à un contrat";
    }
  }
}
</script>

<style scoped>

</style>