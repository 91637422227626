const ValidationRulesMixin = {
    methods: {
        notNullNorEmpty(value) {
            return value !== null && value !== "" || "Ce champ n'a pas été rempli.";
        },
        notEmptyList(value) {
            return Array.isArray(value) ? value.length > 0 : this.notNullNorEmpty(value);
        }
    }
}

export default ValidationRulesMixin;